import styled from "styled-components";

export const ExplorePage = styled.main`
  width: 100%;
  max-width: ${(props) => props.theme.page.width};
  margin: auto;

  @media (max-width: 1024px) {
    max-width: calc(100% - 38px * 2);
    margin: 0 38px;
  }

  @media (max-width: 768px) {
    max-width: calc(100% - 20px * 2);
    margin: 0 20px;
  }
`;

export const LocationInputs = styled.div`
  display: flex;
  justify-content: center;
`;

export const AddressInput = styled.input`
  background: white;
  font-size: 20px;
  border: solid 1px hsl(0, 0%, 80%);
  border-radius: 12px 0px 0px 12px;
  padding: 20px;
  width: 450px;
  outline: none;
  cursor: auto;

  @media (max-width: 768px) {
    width: calc(100% - 140px - 121px);
  }
  @media (max-width: 540px) {
    width: calc(100% - 60px - 106px);
  }
`;

export const HeadCountInput = styled.input`
  background: white;
  font-size: 20px;
  border: solid 1px hsl(0, 0%, 80%);
  padding: 20px 10px;
  width: 140px;
  outline: none;
  cursor: auto;
  @media (max-width: 540px) {
    width: 60px;
  }
`;

export const ExploreButton = styled.button`
  background: ${(props) => props.theme.colors.action};
  color: white;
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  border-radius: 0px 12px 12px 0px;
  padding: 21px 25px;
  cursor: pointer;
  &:hover {
    background: ${(props) => props.theme.colors.actionBright};
  }
  @media (max-width: 540px) {
    font-size: 16px;
  }
`;

export const ExploreInstructions = styled.div`
  font-size: 20px;
  text-align: center;
  margin-top: 80px;
`;
