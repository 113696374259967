import styled from "styled-components";

export const MarkerPin = styled.div`
  background: no-repeat url(/images/marker.svg);
  height: 43px;
  width: 30px;
  position: relative;

  &:hover > div {
    display: block;
  }
`;

export const MarkerLegend = styled.div<{ showLegend: boolean }>`
  position: absolute;
  background: white;
  border-radius: 8px;
  padding: 12px;
  display: ${(props) => (props.showLegend ? "block" : "none")};
  top: -50px;
  left: -80px;
  z-index: 10;
  box-shadow: 0px 3px 6px 2px rgb(0, 0, 0, 0.25);
  width: 200px;
`;

export const MarkerAttribute = styled.div`
  flex-grow: 1;
  flex-shrink: 0;
  padding: 0 10px;
  h4 {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #68665e;
    text-transform: uppercase;
    margin-bottom: 4px;
  }

  p {
    font-weight: 500;
    font-size: 22px;
    text-align: center;
  }
`;
