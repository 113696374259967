export class Api {
  public static async getSheetData(password: string) {
    if (!password) {
      throw new Error("Please type a password");
    }

    const response = await fetch("/api/sheets", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${password}`,
      },
    });

    if (response.status >= 200 && response.status <= 299) {
      const jsonResponse = await response.json();
      return jsonResponse;
    } else if (response.status === 403) {
      throw new Error("Invalid password");
    } else {
      // Handle errors
      console.error(response.status, response.statusText);
      throw new Error("Internal Server Error. Please try again later.");
    }
  }

  public static async getWalkScore(address: string, lat: number, lng: number) {
    const params = new URLSearchParams({
      address,
      lat: lat.toString(),
      lng: lng.toString(),
    });
    const response = await fetch(`/api/walkscore?${params.toString()}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status >= 200 && response.status <= 299) {
      const jsonResponse = await response.json();
      return jsonResponse;
    } else {
      // Handle errors
      console.error(response.status, response.statusText);
      throw new Error("Internal Server Error. Please try again later.");
    }
  }
}
