import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SingleValue } from "react-select";
import Dropdown from "../components/Dropdown";
import { States } from "../data/locations";
import { useSheetData } from "../services/useSheetData";
import { DropdownOption } from "../types/main";
import {
  ResultItem,
  ResultBuildings,
  BuildingName,
  LocationInstructions,
  LocationSelect,
  PortfolioPage,
  ResultBody,
  ResultButton,
  ResultGrid,
  MapContainer,
  BuildingAttribute,
} from "./Portfolio.styled";
import Map from "../components/Maps/Map";

function Portfolio() {
  const navigate = useNavigate();
  const { data, status } = useSheetData();
  const [searchParams, setSearchParams] = useSearchParams();
  const [visibleBuildingId, setVisibleBuildingId] = useState<string | null>(null);

  useEffect(() => {
    if (status === "cache-read" && !data) {
      navigate("/");
    }
  }, [status, data, navigate]);

  const defaultState = States.find((state) => state.abbreviation === searchParams.get("state"));
  const [selectedState, setSelectedState] = useState<DropdownOption | null>(
    defaultState ? { label: defaultState.name, value: defaultState.abbreviation } : null
  );

  const defaultCity = searchParams.get("city");
  const [selectedCity, setSelectedCity] = useState<DropdownOption | null>(
    defaultCity ? { label: defaultCity, value: defaultCity } : null
  );
  const [cities, setCities] = useState<DropdownOption[]>([]);

  const stateOptions = States.map((state) => ({
    label: state.name,
    value: state.abbreviation,
  }));

  useEffect(() => {
    if (!data) {
      return;
    }
    const cities = data.buildings
      .filter((building) => building.State === selectedState?.value)
      .map((building) => building.City);
    const uniqueCities = Array.from(new Set(cities)).sort();
    setCities(uniqueCities.map((city) => ({ label: city, value: city })));
  }, [selectedState, data]);

  const handleChangeState = (option: SingleValue<DropdownOption>) => {
    setSelectedState(option);
    setSelectedCity(null);
    setSearchParams({ state: option?.value || "", city: "" }, { replace: true });
  };

  const handleChangeCity = (option: SingleValue<DropdownOption>) => {
    setSelectedCity(option as DropdownOption);
    setSearchParams({ state: selectedState?.value || "", city: option?.value || "" }, { replace: true });
  };

  const handleResultClick = (buildingId: string) => {
    navigate(`/building/${buildingId}`);
    return;
  };

  const handleItemEnter = (buildingId: string) => {
    setVisibleBuildingId(buildingId);
  };

  const handleItemLeave = (buildingId: string) => {
    setVisibleBuildingId(null);
  };

  if (!data) {
    return null;
  }

  const buildings = data.buildings
    .filter((building) => building.City === selectedCity?.value && building.State === selectedState?.value)
    .sort((buildingA, buildingB) => Number(buildingB.Headcount) - Number(buildingA.Headcount));

  return (
    <PortfolioPage>
      <LocationSelect>
        <Dropdown
          placeholder="Select State"
          options={stateOptions}
          onChange={handleChangeState}
          value={selectedState}
        ></Dropdown>
        <Dropdown
          placeholder="Select City"
          value={selectedCity}
          options={cities}
          onChange={handleChangeCity}
          noOptionsMessage={() => (selectedState ? "No buildings found in this state" : "Select a state")}
        ></Dropdown>
      </LocationSelect>
      {!selectedCity && (
        <LocationInstructions>Select a state and a city to see the list of buildings.</LocationInstructions>
      )}
      {selectedCity && (
        <ResultGrid>
          <MapContainer>
            <Map buildings={buildings} visibleBuildingId={visibleBuildingId}></Map>
          </MapContainer>
          <ResultBuildings>
            {buildings.map((building) => (
              <ResultItem
                key={building.Id}
                onClick={() => handleResultClick(building.Id)}
                onMouseEnter={() => handleItemEnter(building.Id)}
                onMouseLeave={() => handleItemLeave(building.Id)}
              >
                <ResultBody>
                  <BuildingName>
                    <h3>{building.Name}</h3>
                    <p>{building.Address}</p>
                  </BuildingName>
                  <BuildingAttribute>
                    <h4>Headcount</h4>
                    <p>{Number(building.Headcount).toLocaleString("en-US")}</p>
                  </BuildingAttribute>
                  <BuildingAttribute>
                    <h4>Scale</h4>
                    <p>{building.Scale}</p>
                  </BuildingAttribute>
                  <BuildingAttribute>
                    <h4>Category</h4>
                    <p>{building.Category}</p>
                  </BuildingAttribute>
                </ResultBody>
                <ResultButton></ResultButton>
              </ResultItem>
            ))}
          </ResultBuildings>
        </ResultGrid>
      )}
    </PortfolioPage>
  );
}

export default Portfolio;
