import styled from "styled-components";

export const PortfolioPage = styled.main`
  width: 100%;
  max-width: ${(props) => props.theme.page.width};
  margin: auto;

  @media (max-width: 1024px) {
    max-width: calc(100% - 38px * 2);
    margin: 0 38px;
  }

  @media (max-width: 768px) {
    max-width: calc(100% - 20px * 2);
    margin: 0 20px;
  }
`;

export const LocationSelect = styled.div`
  display: flex;
  justify-content: center;
  gap: 30px;
  flex-wrap: wrap;
  @media (max-width: 540px) {
    margin-top: 50px;
  }
`;

export const LocationInstructions = styled.div`
  font-size: 20px;
  text-align: center;
  margin-top: 80px;
`;

export const ResultBuildings = styled.ul`
  width: 100%;
`;

export const ResultItem = styled.li`
  background: #ffffff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  margin-bottom: 24px;
  display: flex;
  border-radius: 16px;
  overflow: hidden;

  &:hover {
    h3 {
      color: ${(props) => props.theme.colors.action};
    }
    button {
      background-color: ${(props) => props.theme.colors.actionBright};
    }
  }
`;

export const BuildingName = styled.div`
  width: 460px;
  > h3 {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
  }
  > p {
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    color: #68665e;
  }
  margin-right: 30px;

  @media (max-width: 1024px) {
    width: 30vw;
    max-width: 100%;
    margin-right: 12px;
  }

  @media (max-width: 768px) {
    width: auto;
    max-width: 100%;
    > h3,
    > p {
      white-space: normal;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
`;

export const ResultBody = styled.div`
  display: flex;
  padding: 26px 0px 26px 36px;
  flex-grow: 1;
  cursor: pointer;
`;

export const ResultButton = styled.button`
  background: ${(props) => props.theme.colors.action} no-repeat center center url(/images/chevron-right.svg);
  width: 79px;
  flex-shrink: 0;
`;

export const BuildingAttribute = styled.div`
  padding: 0 12px;
  border-left: solid 1px #cbcbcb;
  flex-grow: 1;
  flex-shrink: 0;
  h4 {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #68665e;
    text-transform: uppercase;
    margin-bottom: 10px;
    span {
      text-transform: none;
    }
  }

  p {
    font-weight: 500;
    font-size: 28px;
    text-align: center;
  }

  @media (max-width: 768px) {
    display: none;
  }

  &.building {
    @media (max-width: 768px) {
      display: block;
    }

    @media (max-width: 540px) {
      border-left: none;
      margin-bottom: 16px;
      h4 {
        font-size: 14px;
        margin-bottom: 4px;
      }
      p {
        font-size: 20px;
      }
    }
  }
`;

export const HelpIcon = styled.span`
  background: no-repeat url(/images/help.svg) center center;
  display: inline-block;
  height: 18px;
  width: 18px;
  vertical-align: text-top;
  cursor: pointer;
`;

export const ResultGrid = styled.div`
  margin-top: 80px;
  @media (max-width: 768px) {
    margin-top: 50px;
  }
`;

export const MapContainer = styled.div`
  width: 100%;
  height: calc(${(props) => props.theme.page.width} / 3);
  flex-shrink: 0;
  margin: auto;
  margin-bottom: 50px;

  @media (max-width: 1024px) {
    max-width: 100%;
  }
`;
