import styled from "styled-components";

export const FooterEl = styled.footer`
  text-align: center;
  padding-bottom: 26px;
  padding-top: 100px;
`;

export const RefreshButton = styled.button`
  display: inline-block;
  text-decoration: underline;
  background: none;
  padding: 0;
  margin: 0;
`;
