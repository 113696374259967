import React, { SyntheticEvent, useEffect, useState } from "react";
import { BeatLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import { ErrorMessage, LoginButton, LoginForm, LoginPage, PasswordInput } from "./Login.styled";
import { useSheetData } from "../services/useSheetData";

function Login() {
  const navigate = useNavigate();
  const [password, setPassword] = useState<string>("");
  const { data, status, error, fetchApi } = useSheetData();

  useEffect(() => {
    if (data) {
      navigate("/main");
    }
  }, [data, navigate]);

  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    if (status !== "fetching-api") {
      await fetchApi(password);
    }
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  return (
    <LoginPage>
      {error ? <ErrorMessage>{error}</ErrorMessage> : null}
      <LoginForm onSubmit={handleSubmit}>
        <PasswordInput
          type="password"
          placeholder="Enter Password"
          value={password}
          onChange={handlePasswordChange}
        ></PasswordInput>
        <LoginButton type="submit">{status === "fetching-api" ? <BeatLoader color="#ffffff" /> : "Log In"}</LoginButton>
      </LoginForm>
    </LoginPage>
  );
}

export default Login;
