import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { useSheetData } from "../services/useSheetData";
import {
  DownloadReportButton,
  ExistingPortfolioButton,
  IntroText,
  LoadingPage,
  MainPage,
  NavButtons,
  NewLocationButton,
} from "./Main.styled";

function Main() {
  const navigate = useNavigate();
  const { data, status } = useSheetData();

  useEffect(() => {
    if (status === "cache-read" && !data) {
      navigate("/");
    }
  }, [status, data, navigate]);

  if (!data) {
    return (
      <LoadingPage>
        <BeatLoader color="#ffffff" />
      </LoadingPage>
    );
  }

  return (
    <MainPage>
      <IntroText>
        <p>
          The <strong>WFB Employee Service Toolkit</strong> identifies the recommended employee services package for all
          Wells Fargo Administrative Buildings based on scale and access to urban services. It is intended to help site
          managers and other stakeholders identify gaps or strengths in their current service offerings and set a plan
          of action to get to the desired state.
        </p>
        <p>
          Services recommended respond to the Wells Fargo’s goal of being a trusted brand that supports holistic
          employee well-being, personalization and a drive for innovation.
        </p>
      </IntroText>
      <NavButtons>
        <DownloadReportButton href={data.metadata["Full Report Url"]} target="_blank">
          Download Full Strategy Report
        </DownloadReportButton>
        <ExistingPortfolioButton to={"/portfolio"}>Explore Existing Portfolio</ExistingPortfolioButton>
        <NewLocationButton to={"/explore"}>Explore a New Location</NewLocationButton>
      </NavButtons>
    </MainPage>
  );
}

export default Main;
