import React, { useCallback, useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import Map, { LocationMapping } from "../components/Maps/Map";
import { Api } from "../services/api";
import { useSheetData } from "../services/useSheetData";
import type { BuildingData } from "../types/sheets";
import {
  AddressInput,
  ExploreButton,
  ExploreInstructions,
  ExplorePage,
  HeadCountInput,
  LocationInputs,
} from "./Explore.styled";
import {
  BuildingAttribute,
  BuildingName,
  MapContainer,
  ResultBody,
  ResultBuildings,
  ResultButton,
  ResultGrid,
  ResultItem,
} from "./Portfolio.styled";

export default function Explore() {
  const navigate = useNavigate();
  const { data, updateCache } = useSheetData();
  const [address, setAddress] = useState<string>("");
  const [headcount, setHeadcount] = useState<number | null>(null);
  const [buildings, setBuildings] = useState<BuildingData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAddress(e.target.value);
  };

  const handleHeadcountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHeadcount(e.target.valueAsNumber);
  };

  const handleExploreClick = async () => {
    if (!headcount || !address) {
      return;
    }
    // Create a fake building
    const buildings = [
      {
        Id: "0",
        Name: address,
        Address: address,
        City: "",
        State: "",
        Size: "?",
        Headcount: headcount.toString(),
        Scale: headcount >= 1200 ? "L" : headcount >= 350 ? "M" : "S",
        WalkScore: "...",
        Category: "...",
      },
    ];
    setBuildings(buildings);
    setIsLoading(true);
  };

  const handleLocationChange = useCallback(
    async (locations: LocationMapping) => {
      const loc = locations[0];
      const response = await Api.getWalkScore(address, loc.lat(), loc.lng());
      setIsLoading(false);
      setBuildings((buildings) => {
        buildings[0].WalkScore = response.description;
        if (["Walker's Paradise", "Very Walkable"].includes(response.description)) {
          buildings[0].Category = buildings[0].Scale === "S" ? "3" : buildings[0].Scale === "M" ? "6" : "9";
        } else if (["Somewhat Walkable"].includes(response.description)) {
          buildings[0].Category = buildings[0].Scale === "S" ? "2" : buildings[0].Scale === "M" ? "5" : "8";
        } else if (["Car-Dependent", "Very Car-Dependent"].includes(response.description)) {
          buildings[0].Category = buildings[0].Scale === "S" ? "1" : buildings[0].Scale === "M" ? "4" : "7";
        } else {
          console.error(response.description);
          throw new Error();
        }
        return [...buildings];
      });
    },
    [address]
  );

  useEffect(() => {
    if (data && buildings.length > 0 && buildings[0].Category !== "...") {
      console.log(buildings);
      const existingIndex = data.buildings.findIndex((building) => building.Id === buildings[0].Id);
      if (existingIndex !== -1) {
        data.buildings[existingIndex] = buildings[0];
      } else {
        data.buildings.push(buildings[0]);
      }
      updateCache(data);
    }
  }, [buildings, data, updateCache]);

  const handleResultClick = (buildingId: string) => {
    navigate(`/building/${buildingId}`);
    return;
  };

  return (
    <ExplorePage>
      <LocationInputs>
        <AddressInput
          placeholder={"Type an address (including city & state)"}
          value={address}
          onChange={handleAddressChange}
        ></AddressInput>
        <HeadCountInput
          placeholder={"Headcount"}
          type="number"
          min="0"
          value={headcount || ""}
          onChange={handleHeadcountChange}
        ></HeadCountInput>
        <ExploreButton onClick={handleExploreClick}>
          {isLoading ? <BeatLoader color="#ffffff" /> : "Explore"}
        </ExploreButton>
      </LocationInputs>
      {buildings.length === 0 && (
        <ExploreInstructions>To explore a new location, type the address and the headcount.</ExploreInstructions>
      )}
      {buildings.length > 0 && (
        <ResultGrid>
          <MapContainer>
            <Map buildings={buildings} visibleBuildingId={null} onLocation={handleLocationChange}></Map>
          </MapContainer>
          <ResultBuildings>
            {!isLoading &&
              buildings.map((building) => (
                <ResultItem key={building.Id} onClick={() => handleResultClick(building.Id)}>
                  <ResultBody>
                    <BuildingName>
                      <h3>{building.Name}</h3>
                      <p>{building.Address}</p>
                    </BuildingName>
                    <BuildingAttribute>
                      <h4>Headcount</h4>
                      <p>{Number(building.Headcount).toLocaleString("en-US")}</p>
                    </BuildingAttribute>
                    <BuildingAttribute>
                      <h4>Scale</h4>
                      <p>{building.Scale}</p>
                    </BuildingAttribute>
                    <BuildingAttribute>
                      <h4>Category</h4>
                      <p>{building.Category}</p>
                    </BuildingAttribute>
                  </ResultBody>
                  <ResultButton></ResultButton>
                </ResultItem>
              ))}
          </ResultBuildings>
        </ResultGrid>
      )}
    </ExplorePage>
  );
}
