import React, { useCallback, useRef, useState } from "react";
import GoogleMapReact from "google-map-react";
import fromPairs from "lodash.frompairs";
import { BuildingData } from "../../types/sheets";
import Marker from "./Marker";
import useDeepCompareEffect from "use-deep-compare-effect";
import { useEffect } from "react";

type MapProps = {
  buildings: BuildingData[];
  visibleBuildingId: string | null;
  onLocation?: (locations: LocationMapping) => void;
};

export type LocationMapping = { [key: string]: google.maps.LatLng };

function Map({ buildings, visibleBuildingId, onLocation }: MapProps) {
  const [locations, setLocations] = useState<LocationMapping>({});
  const [zoom, setZoom] = useState<number>(3);
  const [center, setCenter] = useState({
    lat: 39.833333,
    lng: -98.583333,
  });

  const mapsRef = useRef<typeof google.maps | null>(null);
  const mapRef = useRef<google.maps.Map | null>(null);

  const handleApiLoaded = async (map: google.maps.Map, maps: typeof google.maps) => {
    mapsRef.current = maps;
    mapRef.current = map;
    setMarkerLocations();
  };

  const setMarkerLocations = useCallback(async () => {
    const maps = mapsRef.current;
    if (!maps) {
      return;
    }
    const geocoder = new maps.Geocoder();
    try {
      const results = await Promise.all(
        buildings.map((building) =>
          geocoder.geocode({ address: `${building.Address}, ${building.City}, ${building.State}` })
        )
      );
      const locations: [string, google.maps.LatLng][] = results.map((result, idx) => [
        buildings[idx].Id,
        result.results[0].geometry.location,
      ]);
      if (locations.length > 1) {
        const bounds = new maps.LatLngBounds();
        for (const location of locations) {
          bounds.extend(location[1]);
        }
        mapRef.current?.fitBounds(bounds);
      } else {
        setZoom(13);
        setCenter(locations[0][1].toJSON());
      }
      setLocations(fromPairs(locations));
    } catch (e) {
      console.error(e);
    }
  }, [buildings]);

  useDeepCompareEffect(() => {
    setMarkerLocations();
  }, [buildings]);

  useEffect(() => {
    if (Object.keys(locations).length > 0 && onLocation) {
      onLocation(locations);
    }
  }, [locations, onLocation]);

  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "" }}
      yesIWantToUseGoogleMapApiInternals
      zoom={zoom}
      center={center}
      onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
    >
      {Object.keys(locations).map((buildingId) => (
        <Marker
          key={buildingId}
          building={buildings.find((building) => building.Id === buildingId)!}
          lat={locations[buildingId].lat()}
          lng={locations[buildingId].lng()}
          showLegend={visibleBuildingId === buildingId}
        ></Marker>
      ))}
    </GoogleMapReact>
  );
}

export default Map;
