import React from "react";
import { useNavigate } from "react-router-dom";
import { useSheetData } from "../services/useSheetData";
import { FooterEl, RefreshButton } from "./Footer.styled";

function Footer() {
  const navigate = useNavigate();
  const { clearCache } = useSheetData();

  const handleRefresh = async () => {
    clearCache();
    navigate("/");
  };

  return (
    <FooterEl>
      © {new Date().getFullYear()}. Wells Fargo - Perkins&amp;Will |{" "}
      <RefreshButton onClick={handleRefresh}>Refresh</RefreshButton>
    </FooterEl>
  );
}

export default Footer;
