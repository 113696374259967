import React from "react";
import type { BuildingData } from "../../types/sheets";
import { MarkerLegend, MarkerPin } from "./styled";

type MarkerProps = {
  lat: number;
  lng: number;
  building: BuildingData;
  showLegend: boolean;
};

export default function Marker({ building, showLegend }: MarkerProps) {
  if (!building) {
    return null;
  }

  return (
    <MarkerPin>
      <MarkerLegend showLegend={showLegend}>
        <strong>{building.Name}</strong>
      </MarkerLegend>
    </MarkerPin>
  );
}
