import React from "react";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import { HomeLink, MainHeader, MainLayout, MainLogo, MainTitle, Navigation } from "./App.styled";
import Building from "./pages/Building";
import Login from "./pages/Login";
import Main from "./pages/Main";
import Portfolio from "./pages/Portfolio";
import { MainTheme } from "./theme";
import Footer from "./components/Footer";
import Explore from "./pages/Explore";

function App() {
  const location = useLocation();
  return (
    <MainLayout>
      <MainHeader>
        <Link to="/" className="logo">
          <MainLogo src={MainTheme.assets.logo} />
        </Link>
        <MainTitle>Employee Services Strategy</MainTitle>
      </MainHeader>
      <Navigation>
        {location.pathname.startsWith("/main") && <>&nbsp;</>}
        {location.pathname.startsWith("/portfolio") && (
          <>
            <HomeLink to="/main">Home</HomeLink> &gt; <span>Existing Portfolio</span>
          </>
        )}
        {location.pathname.startsWith("/building") && (
          <>
            <HomeLink to="/main">Home</HomeLink> &gt;{" "}
            {location.pathname !== "/building/0" && <Link to="/portfolio">Existing Portfolio</Link>}
            {location.pathname === "/building/0" && <Link to="/explore">New Location</Link>} &gt;{" "}
            <span>Tactics for Building</span>
          </>
        )}
        {location.pathname.startsWith("/explore") && (
          <>
            <HomeLink to="/main">Home</HomeLink> &gt; <span>New Location</span>
          </>
        )}
      </Navigation>
      <Routes location={location}>
        <Route path="/" element={<Login />}></Route>
        <Route path="/main" element={<Main />}></Route>
        <Route path="/portfolio" element={<Portfolio />}></Route>
        <Route path="/explore" element={<Explore />}></Route>
        <Route path="/building/:buildingId" element={<Building />}></Route>
      </Routes>
      <Footer></Footer>
    </MainLayout>
  );
}

export default App;
