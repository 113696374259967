import React from "react";
import Select, { GroupBase, Props } from "react-select";

function Dropdown<Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>(
  props: Props<Option, IsMulti, Group> & { backgroundImage?: string }
) {
  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      borderRadius: "12px",
      width: props.backgroundImage ? "100%" : 320,
      fontSize: "20px",
      paddingLeft: "12px",
    }),
    input: (provided: any, state: any) => ({
      ...provided,
      paddingTop: "10px",
      paddingBottom: "10px",
    }),
    singleValue: (provided: any, state: any) => ({
      ...provided,
      background: "no-repeat left center",
      backgroundImage: `url(${props.backgroundImage})`,
      paddingLeft: props.backgroundImage ? "39px" : provided.paddingLeft,
    }),
    indicatorsContainer: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: "#F9F9F9",
      borderRadius: "0px 12px 12px 0",
    }),
    indicatorSeparator: (provided: any, state: any) => ({
      ...provided,
      marginTop: 0,
      marginBottom: 0,
    }),
    indicatorContainer: (provided: any, state: any) => ({
      ...provided,
      padding: "10px",
    }),
  };
  return <Select {...props} styles={customStyles}></Select>;
}

export default Dropdown;
