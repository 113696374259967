import styled from "styled-components";

export const LoginPage = styled.main`
  padding-bottom: 360px;
  margin: 0 38px;
  @media (max-width: 540px) {
    margin: 0 20px;
  }
`;

export const LoginForm = styled.form`
  display: flex;
  justify-content: center;
`;

export const PasswordInput = styled.input`
  background: white;
  font-size: 20px;
  border: solid 1px hsl(0, 0%, 80%);
  border-radius: 12px 0px 0px 12px;
  padding: 20px;
  width: 460px;
  outline: none;
  cursor: auto;

  @media (max-width: 768px) {
    width: calc(90% - 109px);
  }
`;

export const LoginButton = styled.button`
  background: ${(props) => props.theme.colors.action};
  color: white;
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  border-radius: 0px 12px 12px 0px;
  padding: 21px 25px;
  cursor: pointer;
  white-space: nowrap;
  &:hover {
    background: ${(props) => props.theme.colors.actionBright};
  }

  > span {
    max-height: 24px;
  }
`;

export const ErrorMessage = styled.div`
  color: red;
  text-align: center;
  font-size: 20px;
  margin-bottom: 16px;
`;
