import { DefaultTheme } from "styled-components";

const MainTheme: DefaultTheme = {
  assets: {
    logo: "/images/wells-fargo-logo.svg",
  },
  body: {
    fontFamily: "Mulish, Roboto, 'Helvetica Neue', sans-serif",
    background: `linear-gradient(180deg, #FFB800 0%, #FFDC61 53.12%)`,
  },
  colors: {
    text: "#29261A",
    action: "#D2232A",
    actionBright: "#FF0000",
    secondary: "magenta",
  },
  page: {
    width: "960px",
  },
};

export { MainTheme };
