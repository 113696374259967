import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { SingleValue } from "react-select";
import { BeatLoader } from "react-spinners";
import slugify from "slugify";
import DownloadSheetButton from "../components/DownloadSheet";
import Dropdown from "../components/Dropdown";
import ReactTooltip from "react-tooltip";
import { useSheetData } from "../services/useSheetData";
import { DropdownOption } from "../types/main";
import type { TacticData } from "../types/sheets";
import {
  Activities,
  BackButton,
  BuildingAddress,
  BuildingAttributes,
  BuildingPage,
  BuildingTitle,
  ServiceTactics,
  TacticGroup,
  TacticsInstructions,
  TacticsGroupItem,
  TacticsGroups,
  TacticsPriorities,
  TacticsTitle,
  WalkScore,
  Tactic,
  TacticList,
  TacticCategory,
  TacticName,
  TacticInfo,
  TacticDescription,
  SheetNotice,
  TacticsControls,
  TypeFilter,
  GapAnalysisButton,
} from "./Building.styled";
import { LoadingPage } from "./Main.styled";
import { BuildingAttribute, HelpIcon } from "./Portfolio.styled";

type PriorityTuple = [string | null, string | null];
type BuildingTactic = {
  preRequisite: string;
  tactics: TacticData[];
  highlighted: boolean;
};

type TacticType = "Service" | "Space" | "Program";

function Building() {
  const { buildingId } = useParams();
  const navigate = useNavigate();
  const [priorities, setPriorities] = useState<PriorityTuple>([null, null]);
  const [selectedType, setSelectedType] = useState<TacticType | null>(null);
  const { data, status } = useSheetData();
  const gridRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    if (status === "cache-read" && !data) {
      navigate("/");
    }
  }, [status, data, navigate]);

  useLayoutEffect(
    function alignGrid() {
      if (!gridRef.current || window.matchMedia("(max-width: 768px)").matches) {
        return;
      }
      const rects: DOMRect[] = [];
      const rowOffsets: number[] = [];
      const entries = gridRef.current.querySelectorAll<HTMLElement>(":scope > li");
      entries.forEach((li, i) => {
        rects.push(li.getBoundingClientRect());
        if (i % 2 === 1) {
          const offset = rowOffsets.filter((offset) => offset < 0).reduce((sum, curr) => sum + curr, 0);
          li.style.transform = `translateY(${offset}px)`;
        } else {
          const offset = rowOffsets.filter((offset) => offset > 0).reduce((sum, curr) => sum + curr, 0);
          li.style.transform = `translateY(${-offset}px)`;
        }

        if (i % 2 === 1) {
          rowOffsets.push(rects[i].height - rects[i - 1].height);
        }
      });
    },
    [data, priorities, selectedType]
  );

  useEffect(() => {
    gridRef.current?.classList.remove("visible");
    setTimeout(() => {
      gridRef.current?.classList.add("visible");
    }, 0);
  }, [data, priorities, selectedType]);

  if (!data) {
    return (
      <LoadingPage>
        <BeatLoader color="#ffffff" />
      </LoadingPage>
    );
  }

  const buildingInfo = data.buildings.find((building) => building.Id === buildingId);

  if (!buildingInfo) {
    return <p>Building not found.</p>;
  }

  const applicableTactics = data.tactics.filter(
    (tactic) =>
      tactic.scale.includes(buildingInfo.Scale) && tactic["service categories"].includes(buildingInfo.Category)
  );

  let buildingTactics: BuildingTactic[] = data["Pre-requisite"]
    .map((preRequisite) => {
      const tactics = applicableTactics.filter((tactic) => tactic.overview["Pre-requisite"] === preRequisite);
      return { preRequisite, tactics, highlighted: false };
    })
    .filter((buildingTactic) => buildingTactic.tactics.length > 0);

  function getTypeCount(type: TacticType) {
    return buildingTactics.reduce(
      (sum, buildingTactic) => sum + buildingTactic.tactics.filter((tactic) => tactic.overview.Type === type).length,
      0
    );
  }

  const countsByType = {
    Service: getTypeCount("Service"),
    Space: getTypeCount("Space"),
    Program: getTypeCount("Program"),
  };

  const totalCount = buildingTactics.length;

  // Filter by type
  buildingTactics = buildingTactics
    .map((buildingTactic) => {
      if (selectedType) {
        buildingTactic.tactics = buildingTactic.tactics.filter((tactic) => tactic.overview.Type === selectedType);
      }
      return buildingTactic;
    })
    .filter((buildingTactic) => buildingTactic.tactics.length > 0);

  // Highlight and sort
  buildingTactics = buildingTactics
    .map((buildingTactic) => {
      buildingTactic.highlighted =
        buildingTactic.tactics.every(
          (tactic) => tactic["priority 1"].includes(priorities[0]!) || tactic["priority 2"].includes(priorities[1]!)
        ) &&
        buildingTactic.tactics.some(
          (tactic) => tactic["priority 1"].includes(priorities[0]!) && tactic["priority 2"].includes(priorities[1]!)
        );
      return buildingTactic;
    })
    .sort((bTactic1, bTactic2) => {
      const count1 = bTactic1.tactics.reduce(
        (sum, tactic) =>
          sum +
          (tactic["priority 1"].includes(priorities[0]!) ? 1 : 0) +
          (tactic["priority 2"].includes(priorities[1]!) ? 1 : 0),
        bTactic1.highlighted ? 4 : 0
      );
      const count2 = bTactic2.tactics.reduce(
        (sum, tactic) =>
          sum +
          (tactic["priority 1"].includes(priorities[0]!) ? 1 : 0) +
          (tactic["priority 2"].includes(priorities[1]!) ? 1 : 0),
        bTactic2.highlighted ? 4 : 0
      );
      return count2 - count1;
    });

  function getPriorityList(field: keyof TacticData): DropdownOption[] {
    return Array.from(
      new Set(
        buildingTactics.map((buildingTactic) => buildingTactic.tactics.map((tactic) => tactic[field]).flat()).flat()
      )
    )
      .sort()
      .map((value) => ({ label: value as string, value: value as string }));
  }

  const priorities1 = getPriorityList("priority 1");
  const priorities2 = getPriorityList("priority 2");

  const handleChangePriorities = (option: SingleValue<DropdownOption> | null, position: number) => {
    setPriorities((priorities) => {
      const newPriorities: PriorityTuple = [...priorities];
      newPriorities[position] = option?.value ?? null;
      return newPriorities;
    });
  };

  const handleSelectType = (type: TacticType | null) => {
    if (selectedType === type) {
      type = null;
    }
    setSelectedType(type);
  };

  const handleTacticClick = (sheetUrl: string) => {
    if (sheetUrl) {
      window.open(sheetUrl);
    }
  };

  const handleGapAnalysisClick = () => {
    const metaName = `Gap Worksheet ${buildingInfo.Category}`;
    window.open(data["metadata"][metaName], "_blank", "noopener,noreferrer");
  };

  return (
    <BuildingPage>
      <BackButton onClick={() => navigate(-1)} />
      <BuildingTitle>{buildingInfo.Name}</BuildingTitle>
      <BuildingAddress>{buildingInfo.Address}</BuildingAddress>
      <GapAnalysisButton onClick={handleGapAnalysisClick}>
        <div>Gap Analysis Worksheet</div>
      </GapAnalysisButton>
      <BuildingAttributes>
        <BuildingAttribute className="building">
          <h4>Headcount</h4>
          <p>{Number(buildingInfo.Headcount).toLocaleString("en-US")}</p>
        </BuildingAttribute>
        <BuildingAttribute className="building">
          <h4>Size (sq)</h4>
          <p>
            {!isNaN(Number(buildingInfo.Size)) ? Number(buildingInfo.Size).toLocaleString("en-US") : buildingInfo.Size}
          </p>
        </BuildingAttribute>
        <BuildingAttribute className="building">
          <h4>Scale</h4>
          <p>{buildingInfo.Scale}</p>
        </BuildingAttribute>
        <BuildingAttribute className="building">
          <h4>
            Category <HelpIcon data-tip data-for="service-category"></HelpIcon>
            <ReactTooltip id="service-category" type="dark" effect="solid" place="top">
              <span>
                Service categories are a combination of a site’s headcount which is linked to the food service
                operations capacity and business model, and it’s WalkScore. There is a total of 9 service categories.
              </span>
            </ReactTooltip>
          </h4>
          <p>{buildingInfo.Category}</p>
        </BuildingAttribute>
      </BuildingAttributes>
      <WalkScore>
        <h4>
          Walk Score <HelpIcon data-tip data-for="walk-score"></HelpIcon>
          <ReactTooltip id="walk-score" type="dark" effect="solid" place="right">
            <span>
              The walk-score measures the walkability of any address and its distance to amenities or urban services.
            </span>
          </ReactTooltip>
        </h4>
        <h5>{buildingInfo.WalkScore}</h5>
      </WalkScore>
      <Activities>
        <strong>Activities that might be of interest</strong>
        {data.activities[buildingInfo.WalkScore].map((activity) => ` • ${activity}`)}
      </Activities>
      <ServiceTactics>
        <TacticsTitle>Recommended Service Tactics</TacticsTitle>
        <TacticsInstructions>
          <TacticsControls>
            <TypeFilter>
              <p>
                These tactics support the {totalCount} service{" "}
                <span style={{ whiteSpace: "nowrap" }}>pre-requisites</span>, outlined in the strategy report, and
                consists of (click to filter):
              </p>
              <ul>
                <li
                  className={selectedType === "Service" ? "selected" : ""}
                  onClick={() => handleSelectType("Service")}
                >
                  {countsByType.Service} Services
                </li>
                <li
                  className={selectedType === "Program" ? "selected" : ""}
                  onClick={() => handleSelectType("Program")}
                >
                  {countsByType.Program} Programs
                </li>
                <li className={selectedType === "Space" ? "selected" : ""} onClick={() => handleSelectType("Space")}>
                  {countsByType.Space} Spaces
                </li>
              </ul>
            </TypeFilter>
            <TacticsPriorities>
              <p>Select one or two priorities to highlight the tactics that better support the facility priorities.</p>
              <Dropdown
                placeholder="Service Priority 1"
                options={priorities1}
                isClearable={true}
                backgroundImage={"/images/priority1.svg"}
                onChange={(option) => handleChangePriorities(option, 0)}
              ></Dropdown>
              <Dropdown
                placeholder="Service Priority 2"
                options={priorities2}
                isClearable={true}
                backgroundImage={"/images/priority2.svg"}
                onChange={(option) => handleChangePriorities(option, 1)}
              ></Dropdown>
            </TacticsPriorities>
          </TacticsControls>
        </TacticsInstructions>
        <SheetNotice>
          Click on a tactict to access its detailed service sheet.{" "}
          <a href={data.metadata["Service Sheet Guide"]} target="_blank" rel="noreferrer">
            Learn here how to interpret service sheets.
          </a>
        </SheetNotice>
        <TacticsGroups ref={gridRef}>
          {buildingTactics.map((buildingTactic) => (
            <TacticsGroupItem key={buildingTactic.preRequisite}>
              <TacticGroup
                style={{
                  // @ts-ignore
                  "--bg-image": `url("/images/tactics/${slugify(buildingTactic.preRequisite.toLowerCase())}.svg")`,
                }}
                className={buildingTactic.highlighted ? "highlighted" : ""}
              >
                {buildingTactic.preRequisite}
              </TacticGroup>
              <TacticList>
                {buildingTactic.tactics.map((tactic) => (
                  <Tactic
                    key={tactic.overview.Tactic}
                    onClick={() => handleTacticClick(tactic.overview.Sheet)}
                    className={tactic.overview.Sheet !== "" ? "has-sheet" : ""}
                  >
                    <TacticInfo>
                      <TacticCategory>
                        {tactic.overview.Type}{" "}
                        {tactic["priority 1"].includes(priorities[0]!) && (
                          <img src="/images/priority1.svg" alt={priorities[0]!} />
                        )}
                        {tactic["priority 2"].includes(priorities[1]!) && (
                          <img src="/images/priority2.svg" alt={priorities[1]!} />
                        )}
                      </TacticCategory>
                      <TacticName>{tactic.overview.Tactic}</TacticName>
                      <TacticDescription>{tactic.overview.Description}</TacticDescription>
                    </TacticInfo>
                    <DownloadSheetButton></DownloadSheetButton>
                  </Tactic>
                ))}
              </TacticList>
            </TacticsGroupItem>
          ))}
        </TacticsGroups>
      </ServiceTactics>
    </BuildingPage>
  );
}

export default Building;
