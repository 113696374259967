import React from "react";
import styled from "styled-components";

const DownloadSheet = styled.button`
  width: 30px;
  height: 28px;
  padding-left: 8px;
  background: none;
  flex-shrink: 0;
  margin-top: 24px;
`;

function DownloadSheetButton() {
  return (
    <DownloadSheet>
      <svg width="21" height="28" viewBox="0 0 21 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.5 0C2.57174 0 1.6815 0.368749 1.02513 1.02513C0.368749 1.6815 0 2.57174 0 3.5V24.5C0 25.4283 0.368749 26.3185 1.02513 26.9749C1.6815 27.6313 2.57174 28 3.5 28H17.5C18.4283 28 19.3185 27.6313 19.9749 26.9749C20.6313 26.3185 21 25.4283 21 24.5V9.4745C20.9998 8.54632 20.6309 7.65622 19.9745 7L14 1.0255C13.3438 0.369077 12.4537 0.000198231 11.5255 0H3.5ZM12.25 10.5C12.25 10.0359 12.0656 9.59075 11.7374 9.26256C11.4092 8.93437 10.9641 8.75 10.5 8.75C10.0359 8.75 9.59075 8.93437 9.26256 9.26256C8.93437 9.59075 8.75 10.0359 8.75 10.5V16.7755L6.48725 14.5128C6.32582 14.3456 6.13271 14.2123 5.91921 14.1206C5.7057 14.0289 5.47607 13.9806 5.2437 13.9786C5.01134 13.9765 4.7809 14.0208 4.56583 14.1088C4.35076 14.1968 4.15537 14.3267 3.99106 14.4911C3.82675 14.6554 3.6968 14.8508 3.60881 15.0658C3.52082 15.2809 3.47654 15.5113 3.47856 15.7437C3.48058 15.9761 3.52886 16.2057 3.62057 16.4192C3.71229 16.6327 3.84561 16.8258 4.01275 16.9872L9.26275 22.2372C9.59092 22.5653 10.036 22.7496 10.5 22.7496C10.964 22.7496 11.4091 22.5653 11.7372 22.2372L16.9872 16.9872C17.306 16.6572 17.4824 16.2151 17.4784 15.7563C17.4744 15.2975 17.2904 14.8585 16.9659 14.5341C16.6415 14.2096 16.2025 14.0256 15.7437 14.0216C15.2849 14.0176 14.8428 14.194 14.5128 14.5128L12.25 16.7755V10.5Z"
          fill="#D8D8D8"
        />
      </svg>
    </DownloadSheet>
  );
}

export default DownloadSheetButton;
